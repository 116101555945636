!function () {
  function t(t) {
    var e = document.createElement("textarea");
    e.value = t.getText(), e.style.top = "0", e.style.left = "0", e.style.position = "fixed", document.body.appendChild(e), e.focus(), e.select();

    try {
      var o = document.execCommand("copy");
      setTimeout(function () {
        o ? t.success() : t.error();
      }, 1);
    } catch (e) {
      setTimeout(function () {
        t.error(e);
      }, 1);
    }

    document.body.removeChild(e);
  }

  "undefined" != typeof Prism && "undefined" != typeof document && (Prism.plugins.toolbar ? Prism.plugins.toolbar.registerButton("copy-to-clipboard", function (e) {
    var o = e.element,
        n = function (t) {
      var e = {
        copy: "Copy",
        "copy-error": "Press Ctrl+C to copy",
        "copy-success": "Copied!",
        "copy-timeout": 5000
      };

      for (var o in e) {
        for (var n = "data-prismjs-" + o, c = t; c && !c.hasAttribute(n);) c = c.parentElement;

        c && (e[o] = c.getAttribute(n));
      }

      return e;
    }(o),
        c = document.createElement("button");

    c.className = "copy-to-clipboard-button", c.setAttribute("type", "button");
    var r = document.createElement("span");
    return c.appendChild(r), u("copy"), function (e, o) {
      e.addEventListener("click", function () {
        !function (e) {
          navigator.clipboard ? navigator.clipboard.writeText(e.getText()).then(e.success, function () {
            t(e);
          }) : t(e);
        }(o);
      });
    }(c, {
      getText: function () {
        return o.textContent;
      },
      success: function () {
        u("copy-success"), i();
      },
      error: function () {
        u("copy-error"), setTimeout(function () {
          !function (t) {
            window.getSelection().selectAllChildren(t);
          }(o);
        }, 1), i();
      }
    }), c;

    function i() {
      setTimeout(function () {
        u("copy");
      }, n["copy-timeout"]);
    }

    function u(t) {
      r.textContent = n[t], c.setAttribute("data-copy-state", t);
    }
  }) : console.warn("Copy to Clipboard plugin loaded before Toolbar plugin."));
}();
export default {};